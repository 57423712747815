tinymce.addI18n('ca',{
"Redo": "Refer",
"Undo": "Desfer",
"Cut": "Retalla",
"Copy": "Copia",
"Paste": "Enganxa",
"Select all": "Seleccionar-ho tot",
"New document": "Nou document",
"Ok": "Acceptar",
"Cancel": "Cancel\u00b7la",
"Visual aids": "Assist\u00e8ncia visual",
"Bold": "Negreta",
"Italic": "Cursiva",
"Underline": "Subratllat",
"Strikethrough": "Barrat",
"Superscript": "Super\u00edndex",
"Subscript": "Sub\u00edndex",
"Clear formatting": "Eliminar format",
"Align left": "Alinea a l'esquerra",
"Align center": "Alinea al centre",
"Align right": "Alinea a la dreta",
"Justify": "Justificat",
"Bullet list": "Llista no ordenada",
"Numbered list": "Llista enumerada",
"Decrease indent": "Disminuir sagnat",
"Increase indent": "Augmentar sagnat",
"Close": "Tancar",
"Formats": "Formats",
"Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X\/C\/V keyboard shortcuts instead.": "El vostre navegador no suporta l'acc\u00e9s directe al portaobjectes. Si us plau, feu servir les dreceres de teclat Ctrl+X\/C\/V.",
"Headers": "Encap\u00e7alaments",
"Header 1": "Encap\u00e7alament 1",
"Header 2": "Encap\u00e7alament 2",
"Header 3": "Encap\u00e7alament 3",
"Header 4": "Encap\u00e7alament 4",
"Header 5": "Encap\u00e7alament 5",
"Header 6": "Encap\u00e7alament 6",
"Headings": "Encap\u00e7alaments",
"Heading 1": "Encap\u00e7alament 1",
"Heading 2": "Encap\u00e7alament 2",
"Heading 3": "Encap\u00e7alament 3",
"Heading 4": "Encap\u00e7alament 4",
"Heading 5": "Encap\u00e7alament 5",
"Heading 6": "Encap\u00e7alament 6",
"Preformatted": "Preformatat",
"Div": "Div",
"Pre": "Pre",
"Code": "Codi",
"Paragraph": "Par\u00e0graf",
"Blockquote": "Cita",
"Inline": "En l\u00ednia",
"Blocks": "Blocs",
"Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "Enganxar ara est\u00e0 en mode text pla. Els continguts s'enganxaran com a text pla fins que desactivis aquesta opci\u00f3. ",
"Fonts": "Fonts",
"Font Sizes": "Mides de la font",
"Class": "Classe",
"Browse for an image": "Explorar per cercar una imatge",
"OR": "O",
"Drop an image here": "Deixar anar una imatge aqu\u00ed",
"Upload": "Pujar",
"Block": "Bloc",
"Align": "Alinea",
"Default": "Per defecte",
"Circle": "Cercle",
"Disc": "Disc",
"Square": "Quadrat",
"Lower Alpha": "Alfa menor",
"Lower Greek": "Grec menor",
"Lower Roman": "Roman menor",
"Upper Alpha": "Alfa major",
"Upper Roman": "Roman major",
"Anchor...": "Ancoratge...",
"Name": "Nom",
"Id": "Id",
"Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.": "La Id ha de comen\u00e7ar amb una lletra, seguida d'altres lletres, n\u00fameros, punts, ratlles, comes, o guions baixos",
"You have unsaved changes are you sure you want to navigate away?": "Teniu canvis sense desar, esteu segur que voleu deixar-ho ara?",
"Restore last draft": "Restaurar l'\u00faltim esborrany",
"Special characters...": "Car\u00e0cters especials...",
"Source code": "Codi font",
"Insert\/Edit code sample": "Inserir\/Editar tros de codi",
"Language": "Idioma",
"Code sample...": "Mostra de codi...",
"Color Picker": "Selector de colors",
"R": "R",
"G": "G",
"B": "B",
"Left to right": "D'esquerra a dreta",
"Right to left": "De dreta a esquerra",
"Emoticons...": "Emoticones...",
"Metadata and Document Properties": "Metadades i propietats del document",
"Title": "T\u00edtol",
"Keywords": "Paraules clau",
"Description": "Descripci\u00f3",
"Robots": "Robots",
"Author": "Autor",
"Encoding": "Codificaci\u00f3",
"Fullscreen": "Pantalla completa",
"Action": "Acci\u00f3",
"Shortcut": "Drecera",
"Help": "Ajuda",
"Address": "Adre\u00e7a",
"Focus to menubar": "Enfocar la barra de men\u00fa",
"Focus to toolbar": "Enfocar la barra d'eines",
"Focus to element path": "Enfocar la ruta d'elements",
"Focus to contextual toolbar": "Enfocar la barra d'eines contextual",
"Insert link (if link plugin activated)": "Inserir enlla\u00e7 (si el complement d'enlla\u00e7 est\u00e0 activat)",
"Save (if save plugin activated)": "Desar (si el complement desar est\u00e0 activat)",
"Find (if searchreplace plugin activated)": "Cercar (si el complement cercar-reempla\u00e7ar est\u00e0 activat)",
"Plugins installed ({0}):": "Complements instal\u00b7lats ({0}):",
"Premium plugins:": "Complements premium",
"Learn more...": "Apr\u00e8n m\u00e9s...",
"You are using {0}": "Est\u00e0s utilitzant {0}",
"Plugins": "Complements",
"Handy Shortcuts": "Dreceres a m\u00e0",
"Horizontal line": "L\u00ednia horitzontal",
"Insert\/edit image": "Inserir\/editar imatge",
"Image description": "Descripci\u00f3 de la imatge",
"Source": "Font",
"Dimensions": "Dimensions",
"Constrain proportions": "Mantenir proporcions",
"General": "General",
"Advanced": "Avan\u00e7at",
"Style": "Estil",
"Vertical space": "Espai vertical",
"Horizontal space": "Espai horitzontal",
"Border": "Vora",
"Insert image": "Inserir imatge",
"Image...": "Imatge...",
"Image list": "Llista d'imatges",
"Rotate counterclockwise": "Girar a l'esquerra",
"Rotate clockwise": "Girar a la dreta",
"Flip vertically": "Capgirar verticalment",
"Flip horizontally": "Capgirar horitzontalment",
"Edit image": "Editar imatge",
"Image options": "Opcions d'imatge",
"Zoom in": "Ampliar",
"Zoom out": "Empetitir",
"Crop": "Escap\u00e7ar",
"Resize": "Canviar mida",
"Orientation": "Orientaci\u00f3",
"Brightness": "Brillantor",
"Sharpen": "Remarcar vores",
"Contrast": "Contrast",
"Color levels": "Nivells de color",
"Gamma": "Gamma",
"Invert": "Invertir",
"Apply": "Aplicar",
"Back": "Tornar",
"Insert date\/time": "Inserir data\/hora",
"Date\/time": "Data\/hora",
"Insert\/Edit Link": "Inserir\/editar l'enlla\u00e7",
"Insert\/edit link": "Inserir\/editar enlla\u00e7",
"Text to display": "Text per mostrar",
"Url": "URL",
"Open link in...": "Obrir l'enlla\u00e7 a...",
"Current window": "Finestra actual",
"None": "Cap",
"New window": "Finestra nova",
"Remove link": "Treure enlla\u00e7",
"Anchors": "\u00c0ncores",
"Link...": "Enlla\u00e7...",
"Paste or type a link": "Enganxa o escriu un enlla\u00e7",
"The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "L'URL que has escrit sembla una adre\u00e7a de correu electr\u00f2nic. Vols afegir-li el prefix obligatori mailto: ?",
"The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?": "L'URL que has escrit sembla un enlla\u00e7 extern. Vols afegir-li el prefix obligatori http:\/\/ ?",
"Link list": "Llista d'enlla\u00e7os",
"Insert video": "Inserir v\u00eddeo",
"Insert\/edit video": "Inserir\/editar v\u00eddeo",
"Insert\/edit media": "Inserir\/editar mitj\u00e0",
"Alternative source": "Font alternativa",
"Alternative source URL": "URL de font alternativa",
"Media poster (Image URL)": "Cartell de multim\u00e8dia (URL d'imatge)",
"Paste your embed code below:": "Enganxau el codi a sota:",
"Embed": "Incloure",
"Media...": "Multim\u00e8dia...",
"Nonbreaking space": "Espai fixe",
"Page break": "Salt de p\u00e0gina",
"Paste as text": "Enganxar com a text",
"Preview": "Previsualitzaci\u00f3",
"Print...": "Imprimir...",
"Save": "Desa",
"Find": "Buscar",
"Replace with": "Rempla\u00e7ar amb",
"Replace": "Rempla\u00e7ar",
"Replace all": "Rempla\u00e7ar-ho tot",
"Previous": "Anterior",
"Next": "Seg\u00fcent",
"Find and replace...": "Cercar i reempla\u00e7ar...",
"Could not find the specified string.": "No es pot trobar el text especificat.",
"Match case": "Coincidir maj\u00fascules",
"Find whole words only": "Cercar nom\u00e9s paraules completes",
"Spell check": "Corrector ortogr\u00e0fic",
"Ignore": "Ignorar",
"Ignore all": "Ignorar tots",
"Finish": "Finalitzar",
"Add to Dictionary": "Afegir al diccionari",
"Insert table": "Inserir taula",
"Table properties": "Propietats de taula",
"Delete table": "Esborrar taula",
"Cell": "Cel\u00b7la",
"Row": "Fila",
"Column": "Columna",
"Cell properties": "Propietats de cel\u00b7la",
"Merge cells": "Fusionar cel\u00b7les",
"Split cell": "Dividir cel\u00b7les",
"Insert row before": "Inserir fila a sobre",
"Insert row after": "Inserir fila a sota",
"Delete row": "Esborrar fila",
"Row properties": "Propietats de fila",
"Cut row": "Retallar fila",
"Copy row": "Copiar fila",
"Paste row before": "Enganxar fila a sobre",
"Paste row after": "Enganxar fila a sota",
"Insert column before": "Inserir columna abans",
"Insert column after": "Inserir columna despr\u00e9s",
"Delete column": "Esborrar columna",
"Cols": "Cols",
"Rows": "Files",
"Width": "Amplada",
"Height": "Al\u00e7ada",
"Cell spacing": "Espai entre cel\u00b7les",
"Cell padding": "Marge intern",
"Show caption": "Mostrar encap\u00e7alament",
"Left": "A l'esquerra",
"Center": "Centrat",
"Right": "A la dreta",
"Cell type": "Tipus de cel\u00b7la",
"Scope": "\u00c0mbit",
"Alignment": "Aliniament",
"H Align": "Al\u00edniament H",
"V Align": "Al\u00edniament V",
"Top": "Superior",
"Middle": "Mitj\u00e0",
"Bottom": "Inferior",
"Header cell": "Cel\u00b7la de cap\u00e7alera",
"Row group": "Grup de fila",
"Column group": "Grup de columna",
"Row type": "Tipus de fila",
"Header": "Cap\u00e7alera",
"Body": "Cos",
"Footer": "Peu",
"Border color": "Color de vora",
"Insert template...": "Inserir plantilla...",
"Templates": "Plantilles",
"Template": "Plantilla",
"Text color": "Color del text",
"Background color": "Color del fons",
"Custom...": "Personalitzar...",
"Custom color": "Personalitzar el color",
"No color": "Sense color",
"Remove color": "Eliminar el color",
"Table of Contents": "Taula de continguts",
"Show blocks": "Mostrar blocs",
"Show invisible characters": "Mostrar car\u00e0cters invisibles",
"Word count": "Recompte de paraules",
"Words: {0}": "Paraules: {0}",
"{0} words": "{0} paraules",
"File": "Arxiu",
"Edit": "Edici\u00f3",
"Insert": "Inserir",
"View": "Veure",
"Format": "Format",
"Table": "Taula",
"Tools": "Eines",
"Powered by {0}": "Impulsat per {0}",
"Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "\u00c0rea de text amb format. Premeu ALT-F9 per mostrar el men\u00fa, ALT F10 per la barra d'eines i ALT-0 per ajuda.",
"Image title": "T\u00edtol de la imatge",
"Border width": "Amplada de la vora",
"Border style": "Estil de la vora",
"Error": "Error",
"Warn": "Alerta",
"Valid": "V\u00e0lid",
"To open the popup, press Shift+Enter": "Per obrir la finestra emergent, premeu Maj.+Retorn",
"Rich Text Area. Press ALT-0 for help.": "\u00c0rea de Text enriquit. Premeu ALT-0 per obtenir ajuda.",
"System Font": "Font del sistema",
"Failed to upload image: {0}": "No s'ha pogut carregar la imatge: {0}",
"Failed to load plugin: {0} from url {1}": "No s'ha pogut carregar el complement: {0} de l\u2019URL {1}",
"Failed to load plugin url: {0}": "No s'ha pogut carregar l\u2019URL del complement: {0}",
"Failed to initialize plugin: {0}": "No s'ha pogut inicialitzar el complement: {0}",
"example": "exemple",
"Search": "Cerca",
"All": "Tot",
"Currency": "Moneda",
"Text": "Text",
"Quotations": "Cites",
"Mathematical": "S\u00edmbols matem\u00e0tics",
"Extended Latin": "Llat\u00ed ampliat",
"Symbols": "S\u00edmbols",
"Arrows": "Fletxes",
"User Defined": "Definit per l'usuari",
"dollar sign": "signe del d\u00f2lar",
"currency sign": "signe de la moneda",
"euro-currency sign": "signe de l'euro",
"colon sign": "signe del col\u00f3n",
"cruzeiro sign": "signe del cruzeiro",
"french franc sign": "signe del franc franc\u00e8s",
"lira sign": "signe de la lira",
"mill sign": "signe del mill",
"naira sign": "signe de la naira",
"peseta sign": "signe de la pesseta",
"rupee sign": "signe de la rupia",
"won sign": "signe del won",
"new sheqel sign": "signe del nou x\u00e9quel",
"dong sign": "signe del dong",
"kip sign": "signe del kip",
"tugrik sign": "signe del t\u00f6gr\u00f6g",
"drachma sign": "signe del dracma",
"german penny symbol": "signe del penic alemany",
"peso sign": "signe del peso",
"guarani sign": "signe del guaran\u00ed",
"austral sign": "signe de l\u2019austral",
"hryvnia sign": "signe de la hr\u00edvnia",
"cedi sign": "signe del cedi",
"livre tournois sign": "signe de la lliura tornesa",
"spesmilo sign": "signe de l\u2019spesmilo",
"tenge sign": "signe del tenge",
"indian rupee sign": "signe de la rupia \u00edndia",
"turkish lira sign": "signe de la lira turca",
"nordic mark sign": "signe del marc n\u00f2rdic",
"manat sign": "signe del manat",
"ruble sign": "signe del ruble",
"yen character": "signe del ien",
"yuan character": "signe del iuan",
"yuan character, in hong kong and taiwan": "signe del iuan en Hong Kong i Taiwan",
"yen\/yuan character variant one": "variaci\u00f3 1 del signe del ien\/iuan",
"Loading emoticons...": "Carregant les emoticones...",
"Could not load emoticons": "No s'han pogut carregar les emoticones",
"People": "Gent",
"Animals and Nature": "Animals i natura",
"Food and Drink": "Menjar i beure",
"Activity": "Activitat",
"Travel and Places": "Viatges i llocs",
"Objects": "Objectes",
"Flags": "Banderes",
"Characters": "Car\u00e0cters",
"Characters (no spaces)": "Car\u00e0cters (sense espais)",
"Error: Form submit field collision.": "Error: error en el camp d\u2019enviament del formulari.",
"Error: No form element found.": "Error: no s'ha trobat l'element del formulari.",
"Update": "Actualitzar",
"Color swatch": "Mostra de color",
"Turquoise": "Turquesa",
"Green": "Verd",
"Blue": "Blau",
"Purple": "Violeta",
"Navy Blue": "Blau mar\u00ed",
"Dark Turquoise": "Turquesa fosc",
"Dark Green": "Verd fosc",
"Medium Blue": "Blau mitj\u00e0",
"Medium Purple": "Violeta mitj\u00e0",
"Midnight Blue": "Blau mitjanit",
"Yellow": "Groc",
"Orange": "Taronja",
"Red": "Vermell",
"Light Gray": "Gris clar",
"Gray": "Gris",
"Dark Yellow": "Groc fosc",
"Dark Orange": "Taronja fosc",
"Dark Red": "Vermell fosc",
"Medium Gray": "Gris mitj\u00e0",
"Dark Gray": "Gris fosc",
"Black": "Negre",
"White": "Blanc",
"Switch to or from fullscreen mode": "Canviar a o del mode de pantalla completa",
"Open help dialog": "Obrir el quadre de di\u00e0leg d'ajuda",
"history": "historial",
"styles": "estils",
"formatting": "format",
"alignment": "alineaci\u00f3",
"indentation": "sagnat",
"permanent pen": "retolador permanent",
"comments": "comentaris",
"Anchor": "\u00c0ncora",
"Special character": "Car\u00e0cter especial",
"Code sample": "Mostra de codi",
"Color": "Color",
"Emoticons": "Emoticones",
"Document properties": "Propietats del document",
"Image": "Imatge",
"Insert link": "Inserir enlla\u00e7",
"Target": "Dest\u00ed",
"Link": "Enlla\u00e7",
"Poster": "P\u00f3ster",
"Media": "Mitjans",
"Print": "Imprimir",
"Prev": "Anterior",
"Find and replace": "Buscar i rempla\u00e7ar",
"Whole words": "Paraules senceres",
"Spellcheck": "Comprovar ortrografia",
"Caption": "Encap\u00e7alament",
"Insert template": "Inserir plantilla"
});