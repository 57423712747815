tinymce.addI18n('es',{
"Redo": "Rehacer",
"Undo": "Deshacer",
"Cut": "Cortar",
"Copy": "Copiar",
"Paste": "Pegar",
"Select all": "Seleccionar todo",
"New document": "Nuevo documento",
"Ok": "Ok",
"Cancel": "Cancelar",
"Visual aids": "Ayudas visuales",
"Bold": "Negrita",
"Italic": "Cursiva",
"Underline": "Subrayado",
"Strikethrough": "Tachado",
"Superscript": "Super\u00edndice",
"Subscript": "Sub\u00edndice",
"Clear formatting": "Limpiar formato",
"Align left": "Alinear a la izquierda",
"Align center": "Alinear al centro",
"Align right": "Alinear a la derecha",
"Justify": "Justificar",
"Bullet list": "Lista de vi\u00f1etas",
"Numbered list": "Lista numerada",
"Decrease indent": "Disminuir sangr\u00eda",
"Increase indent": "Incrementar sangr\u00eda",
"Close": "Cerrar",
"Formats": "Formatos",
"Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X\/C\/V keyboard shortcuts instead.": "Su navegador no es compatible con el acceso directo al portapapeles. Use las teclas Crtl+X\/C\/V de su teclado.",
"Headers": "Encabezados",
"Header 1": "Encabezado 1",
"Header 2": "Encabezado 2",
"Header 3": "Encabezado 3",
"Header 4": "Encabezado 4",
"Header 5": "Encabezado 5",
"Header 6": "Encabezado 6",
"Headings": "Encabezados",
"Heading 1": "Encabezado 1",
"Heading 2": "Encabezado 2",
"Heading 3": "Encabezado 3",
"Heading 4": "Encabezado 4",
"Heading 5": "Encabezado 5",
"Heading 6": "Encabezado 6",
"Preformatted": "Con formato previo",
"Div": "Div",
"Pre": "Pre",
"Code": "C\u00f3digo",
"Paragraph": "P\u00e1rrafo",
"Blockquote": "Blockquote",
"Inline": "Alineado",
"Blocks": "Bloques",
"Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "Pegar est\u00e1 ahora en modo de texto plano. El contenido se pegar\u00e1 como texto plano hasta que desactive esta opci\u00f3n.",
"Fonts": "Fuentes",
"Font Sizes": "Tama\u00f1os de fuente",
"Class": "Clase",
"Browse for an image": "Buscar una imagen",
"OR": "O",
"Drop an image here": "Arrastre una imagen aqu\u00ed",
"Upload": "Cargar",
"Block": "Bloque",
"Align": "Alinear",
"Default": "Por defecto",
"Circle": "C\u00edrculo",
"Disc": "Disco",
"Square": "Cuadrado",
"Lower Alpha": "Inferior Alfa",
"Lower Greek": "Inferior Griega",
"Lower Roman": "Inferior Romana",
"Upper Alpha": "Superior Alfa",
"Upper Roman": "Superior Romana",
"Anchor...": "Anclaje...",
"Name": "Nombre",
"Id": "Id",
"Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.": "Deber\u00eda comenzar por una letra, seguida solo de letras, n\u00fameros, guiones, puntos, dos puntos o guiones bajos.",
"You have unsaved changes are you sure you want to navigate away?": "Tiene cambios sin guardar. \u00bfEst\u00e1 seguro de que quiere salir?",
"Restore last draft": "Restaurar el \u00faltimo borrador",
"Special characters...": "Caracteres especiales...",
"Source code": "C\u00f3digo fuente",
"Insert\/Edit code sample": "Insertar\/editar c\u00f3digo de prueba",
"Language": "Idioma",
"Code sample...": "Ejemplo de c\u00f3digo...",
"Color Picker": "Selector de colores",
"R": "R",
"G": "V",
"B": "A",
"Left to right": "De izquierda a derecha",
"Right to left": "De derecha a izquierda",
"Emoticons...": "Emoticones...",
"Metadata and Document Properties": "Metadatos y propiedades del documento",
"Title": "T\u00edtulo",
"Keywords": "Palabras clave",
"Description": "Descripci\u00f3n",
"Robots": "Robots",
"Author": "Autor",
"Encoding": "Codificaci\u00f3n",
"Fullscreen": "Pantalla completa",
"Action": "Acci\u00f3n",
"Shortcut": "Atajo",
"Help": "Ayuda",
"Address": "Direcci\u00f3n",
"Focus to menubar": "Enfocar la barra del men\u00fa",
"Focus to toolbar": "Enfocar la barra de herramientas",
"Focus to element path": "Enfocar la ruta del elemento",
"Focus to contextual toolbar": "Enfocar la barra de herramientas contextual",
"Insert link (if link plugin activated)": "Insertar enlace (si el complemento de enlace est\u00e1 activado)",
"Save (if save plugin activated)": "Guardar (si el componente de salvar est\u00e1 activado)",
"Find (if searchreplace plugin activated)": "Buscar (si el complemento buscar-remplazar est\u00e1 activado)",
"Plugins installed ({0}):": "Plugins instalados ({0}):",
"Premium plugins:": "Complementos premium:",
"Learn more...": "Aprende m\u00e1s...",
"You are using {0}": "Estas usando {0}",
"Plugins": "Complementos",
"Handy Shortcuts": "Accesos directos",
"Horizontal line": "L\u00ednea horizontal",
"Insert\/edit image": "Insertar\/editar imagen",
"Image description": "Descripci\u00f3n de la imagen",
"Source": "Enlace",
"Dimensions": "Dimensiones",
"Constrain proportions": "Restringir proporciones",
"General": "General",
"Advanced": "Avanzado",
"Style": "Estilo",
"Vertical space": "Espacio vertical",
"Horizontal space": "Espacio horizontal",
"Border": "Borde",
"Insert image": "Insertar imagen",
"Image...": "Imagen...",
"Image list": "Lista de im\u00e1genes",
"Rotate counterclockwise": "Girar a la izquierda",
"Rotate clockwise": "Girar a la derecha",
"Flip vertically": "Invertir verticalmente",
"Flip horizontally": "Invertir horizontalmente",
"Edit image": "Editar imagen",
"Image options": "Opciones de imagen",
"Zoom in": "Acercar",
"Zoom out": "Alejar",
"Crop": "Recortar",
"Resize": "Redimensionar",
"Orientation": "Orientaci\u00f3n",
"Brightness": "Brillo",
"Sharpen": "Forma",
"Contrast": "Contraste",
"Color levels": "Niveles de color",
"Gamma": "Gamma",
"Invert": "Invertir",
"Apply": "Aplicar",
"Back": "Atr\u00e1s",
"Insert date\/time": "Insertar fecha\/hora",
"Date\/time": "Fecha\/hora",
"Insert\/Edit Link": "Insertar\/editar enlace",
"Insert\/edit link": "Insertar\/editar enlace",
"Text to display": "Texto para mostrar",
"Url": "URL",
"Open link in...": "Abrir enlace en...",
"Current window": "Ventana actual",
"None": "Ninguno",
"New window": "Nueva ventana",
"Remove link": "Quitar enlace",
"Anchors": "Anclas",
"Link...": "Enlace...",
"Paste or type a link": "Pega o introduce un enlace",
"The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "El enlace que has introducido no parece ser una direcci\u00f3n de correo electr\u00f3nico. Quieres a\u00f1adir el prefijo necesario mailto: ?",
"The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?": "El enlace que has introducido no parece ser una enlace externo. Quieres a\u00f1adir el prefijo necesario http:\/\/ ?",
"Link list": "Lista de enlaces",
"Insert video": "Insertar video",
"Insert\/edit video": "Insertar\/editar video",
"Insert\/edit media": "Insertar\/editar medio",
"Alternative source": "Enlace alternativo",
"Alternative source URL": "Origen de URL alternativo",
"Media poster (Image URL)": "P\u00f3ster de medio (URL de imagen)",
"Paste your embed code below:": "Pega tu c\u00f3digo embebido debajo",
"Embed": "Incrustado",
"Media...": "Medios...",
"Nonbreaking space": "Espacio fijo",
"Page break": "Salto de p\u00e1gina",
"Paste as text": "Pegar como texto",
"Preview": "Previsualizar",
"Print...": "Imprimir...",
"Save": "Guardar",
"Find": "Buscar",
"Replace with": "Reemplazar con",
"Replace": "Reemplazar",
"Replace all": "Reemplazar todo",
"Previous": "Anterior",
"Next": "Siguiente",
"Find and replace...": "Buscar y reemplazar...",
"Could not find the specified string.": "No se encuentra la cadena de texto especificada",
"Match case": "Coincidencia exacta",
"Find whole words only": "Solo palabras completas",
"Spell check": "Revisar ortograf\u00eda",
"Ignore": "Ignorar",
"Ignore all": "Ignorar todos",
"Finish": "Finalizar",
"Add to Dictionary": "A\u00f1adir al Diccionario",
"Insert table": "Insertar tabla",
"Table properties": "Propiedades de la tabla",
"Delete table": "Eliminar tabla",
"Cell": "Celda",
"Row": "Fila",
"Column": "Columna",
"Cell properties": "Propiedades de la celda",
"Merge cells": "Combinar celdas",
"Split cell": "Dividir celdas",
"Insert row before": "Insertar fila antes",
"Insert row after": "Insertar fila despu\u00e9s ",
"Delete row": "Eliminar fila",
"Row properties": "Propiedades de la fila",
"Cut row": "Cortar fila",
"Copy row": "Copiar fila",
"Paste row before": "Pegar la fila antes",
"Paste row after": "Pegar la fila despu\u00e9s",
"Insert column before": "Insertar columna antes",
"Insert column after": "Insertar columna despu\u00e9s",
"Delete column": "Eliminar columna",
"Cols": "Columnas",
"Rows": "Filas",
"Width": "Ancho",
"Height": "Alto",
"Cell spacing": "Espacio entre celdas",
"Cell padding": "Relleno de celda",
"Show caption": "Mostrar t\u00edtulo",
"Left": "Izquierda",
"Center": "Centrado",
"Right": "Derecha",
"Cell type": "Tipo de celda",
"Scope": "\u00c1mbito",
"Alignment": "Alineaci\u00f3n",
"H Align": "Alineamiento Horizontal",
"V Align": "Alineamiento Vertical",
"Top": "Arriba",
"Middle": "Centro",
"Bottom": "Abajo",
"Header cell": "Celda de la cebecera",
"Row group": "Grupo de filas",
"Column group": "Grupo de columnas",
"Row type": "Tipo de fila",
"Header": "Cabecera",
"Body": "Cuerpo",
"Footer": "Pie de p\u00e1gina",
"Border color": "Color del borde",
"Insert template...": "Insertar plantilla...",
"Templates": "Plantillas",
"Template": "Plantilla",
"Text color": "Color del texto",
"Background color": "Color de fondo",
"Custom...": "Personalizar...",
"Custom color": "Color personalizado",
"No color": "Sin color",
"Remove color": "Quitar color",
"Table of Contents": "Tabla de contenidos",
"Show blocks": "Mostrar bloques",
"Show invisible characters": "Mostrar caracteres invisibles",
"Word count": "Contar palabras",
"Words: {0}": "Palabras: {0}",
"{0} words": "{0} palabras",
"File": "Archivo",
"Edit": "Editar",
"Insert": "Insertar",
"View": "Ver",
"Format": "Formato",
"Table": "Tabla",
"Tools": "Herramientas",
"Powered by {0}": "Desarrollado por {0}",
"Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "\u00c1rea de texto enriquecido. Pulse ALT-F9 para el menu. Pulse ALT-F10 para la barra de herramientas. Pulse ALT-0 para ayuda",
"Image title": "Titulo de imagen",
"Border width": "Ancho de borde",
"Border style": "Estilo de borde",
"Error": "Error",
"Warn": "Advertencia",
"Valid": "V\u00e1lido",
"To open the popup, press Shift+Enter": "Para abrir el elemento emergente, pulse May\u00fas+Intro",
"Rich Text Area. Press ALT-0 for help.": "\u00c1rea de texto enriquecido. Pulse ALT-0 para abrir la ayuda.",
"System Font": "Fuente de sistema",
"Failed to upload image: {0}": "Fallo al cargar imagen: {0}",
"Failed to load plugin: {0} from url {1}": "Fallo al cargar complemento: {0} desde URL {1}",
"Failed to load plugin url: {0}": "Fallo al cargar URL del complemento: {0}",
"Failed to initialize plugin: {0}": "Fallo al iniciar el complemento: {0}",
"example": "ejemplo",
"Search": "Buscar",
"All": "Todo",
"Currency": "Divisa",
"Text": "Texto",
"Quotations": "Comillas",
"Mathematical": "S\u00edmbolo matem\u00e1tico",
"Extended Latin": "Latino extendido A",
"Symbols": "S\u00edmbolos",
"Arrows": "Flechas",
"User Defined": "Definido por el usuario",
"dollar sign": "signo de d\u00f3lar",
"currency sign": "signo de divisa",
"euro-currency sign": "signo de euro",
"colon sign": "signo de dos puntos",
"cruzeiro sign": "signo de cruceiro",
"french franc sign": "signo de franco franc\u00e9s",
"lira sign": "signo de lira",
"mill sign": "signo de mill",
"naira sign": "signo de naira",
"peseta sign": "signo de peseta",
"rupee sign": "signo de rupia",
"won sign": "signo de won",
"new sheqel sign": "signo de nuevo s\u00e9quel",
"dong sign": "signo de dong",
"kip sign": "signo de kip",
"tugrik sign": "signo de tugrik",
"drachma sign": "signo de dracma",
"german penny symbol": "signo de penique alem\u00e1n",
"peso sign": "signo de peso",
"guarani sign": "signo de guaran\u00ed",
"austral sign": "signo de austral",
"hryvnia sign": "signo de grivna",
"cedi sign": "signo de cedi",
"livre tournois sign": "signo de libra tornesa",
"spesmilo sign": "signo de spesmilo",
"tenge sign": "signo de tenge",
"indian rupee sign": "signo de rupia india",
"turkish lira sign": "signo de lira turca",
"nordic mark sign": "signo de marco n\u00f3rdico",
"manat sign": "signo de manat",
"ruble sign": "signo de rublo",
"yen character": "car\u00e1cter de yen",
"yuan character": "car\u00e1cter de yuan",
"yuan character, in hong kong and taiwan": "car\u00e1cter de yuan en Hong Kong y Taiw\u00e1n",
"yen\/yuan character variant one": "Variante uno de car\u00e1cter de yen\/yuan",
"Loading emoticons...": "Cargando emoticonos...",
"Could not load emoticons": "No se han podido cargar los emoticonos",
"People": "Personas",
"Animals and Nature": "Animales y naturaleza",
"Food and Drink": "Comida y bebida",
"Activity": "Actividad",
"Travel and Places": "Viajes y lugares",
"Objects": "Objetos",
"Flags": "Banderas",
"Characters": "Caracteres",
"Characters (no spaces)": "Caracteres (sin espacios)",
"Error: Form submit field collision.": "Error: Colisi\u00f3n de campo al enviar formulario.",
"Error: No form element found.": "Error: No se encuentra ning\u00fan elemento de formulario.",
"Update": "Actualizar",
"Color swatch": "Muestrario de colores",
"Turquoise": "Turquesa",
"Green": "Verde",
"Blue": "Azul",
"Purple": "P\u00farpura",
"Navy Blue": "Azul marino",
"Dark Turquoise": "Turquesa oscuro",
"Dark Green": "Verde oscuro",
"Medium Blue": "Azul medio",
"Medium Purple": "P\u00farpura medio",
"Midnight Blue": "Azul medio",
"Yellow": "Amarillo",
"Orange": "Naranja",
"Red": "Rojo",
"Light Gray": "Gris claro",
"Gray": "Gris",
"Dark Yellow": "Amarillo oscuro",
"Dark Orange": "Naranja oscuro",
"Dark Red": "Rojo oscuro",
"Medium Gray": "Gris medio",
"Dark Gray": "Gris oscuro",
"Black": "Negro",
"White": "Blanco",
"Switch to or from fullscreen mode": "Activar o desactivar modo pantalla completa",
"Open help dialog": "Abrir di\u00e1logo de ayuda",
"history": "historial",
"styles": "estilos",
"formatting": "formato",
"alignment": "alineaci\u00f3n",
"indentation": "sangr\u00eda",
"permanent pen": "bol\u00edgrafo permanente",
"comments": "comentarios",
"Anchor": "Ancla",
"Special character": "Car\u00e1cter especial",
"Code sample": "Ejemplo de c\u00f3digo",
"Color": "Color",
"Emoticons": "Emoticonos",
"Document properties": "Propiedades del documento",
"Image": "Imagen",
"Insert link": "Insertar enlace",
"Target": "Destino",
"Link": "Enlace",
"Poster": "Miniatura",
"Media": "Media",
"Print": "Imprimir",
"Prev": "Anterior",
"Find and replace": "Buscar y reemplazar",
"Whole words": "Palabras completas",
"Spellcheck": "Corrector ortogr\u00e1fico",
"Caption": "Subt\u00edtulo",
"Insert template": "Insertar plantilla"
});